import startOfToday from 'date-fns/startOfToday';
import { theme } from 'twin.macro';
import { HeaderCellProps } from '../component/molecule/Table/TableElement.molecule';
import { UseTranslator } from '../hook/useTranslator.hook';
import {
  JOSOCandidateFilterFormSortBy,
  JOSOCandidateFilterFormStatus,
} from '../model/ShipperOrder.model';
import { JOBOORDERTYPE } from './JobOrder.constant';
import { DELIVERYLOCATIONTYPE } from './Location.constant';

// #region INTERFACES
export type MapJOSOCandidateActiveFiltersLabelParams = {
  status: JOSOCandidateFilterFormStatus[];
  shipperName: string | undefined;
  locationName: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
};

export type JOSOCandidateFilterFormInitialValues = {
  sortBy: JOSOCandidateFilterFormSortBy;
  status: JOSOCandidateFilterFormStatus[];
  shipperId: string | undefined;
  locationId: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
};

export type JOSOCandidateFilterSortByByValue = {
  label: string;
  value: JOSOCandidateFilterFormSortBy;
};
export type JOSOCandidateFilterFormStatusValue = {
  label: string;
  value: JOSOCandidateFilterFormStatus;
};
// #endregion

export const JOSOSelectionColumns = (
  translate: UseTranslator['translate'],
): HeaderCellProps[] => [
  {
    label: translate(''),
    accessor: 'check',
    disableSort: true,
    headerStyle: {
      borderTopLeftRadius: '0.5rem',
      width: '60px',
      paddingRight: '25px',
    },
    cellStyle: {
      backgroundColor: theme`colors.beige.bg`,
      width: '60px',
      paddingRight: '25px',
    },
  },
  {
    label: translate('SO Number/Ref'),
    accessor: 'number',
    disableSort: true,
    headerStyle: {
      maxWidth: '185px',
      paddingLeft: '5px',
    },
    cellStyle: {
      backgroundColor: theme`colors.beige.bg`,
      paddingLeft: '5px',
    },
  },
  {
    label: translate('Pickup Location'),
    accessor: 'pickupLocation',
    disableSort: true,
    headerStyle: {
      width: '160px',
    },
    cellStyle: {
      backgroundColor: theme`colors.beige.bg`,
    },
  },
  {
    label: translate('Dropoff Location'),
    accessor: 'dropoffLocation',
    disableSort: true,
    headerStyle: {
      width: '160px',
    },
    cellStyle: {
      backgroundColor: theme`colors.beige.bg`,
    },
  },
  {
    label: translate('Current Location'),
    accessor: 'currentLocation',
    disableSort: true,
    headerStyle: {
      width: '160px',
    },
    cellStyle: {
      backgroundColor: theme`colors.beige.bg`,
    },
  },
  {
    label: translate('Delivery Location'),
    accessor: 'deliveryLocation',
    disableSort: true,
    headerStyle: {
      width: '160px',
    },
    cellStyle: {
      backgroundColor: theme`colors.beige.bg`,
    },
  },
  {
    label: translate('Status'),
    accessor: 'status',
    disableSort: true,
    headerStyle: {
      width: '140px',
    },
    cellStyle: {
      backgroundColor: theme`colors.beige.bg`,
    },
  },
  {
    label: translate('Date'),
    accessor: 'soDate',
    disableSort: true,
    headerStyle: {
      width: '120px',
    },
    cellStyle: {
      backgroundColor: theme`colors.beige.bg`,
    },
  },
  {
    label: translate('Shipper'),
    accessor: 'shipper',
    disableSort: true,
    headerStyle: {
      width: '160px',
      borderTopRightRadius: '0.5rem',
    },
    cellStyle: {
      backgroundColor: theme`colors.beige.bg`,
      position: 'sticky',
      right: 0,
      textAlign: 'right',
    },
  },
];

export const JOSOCandidateColumns = (
  translate: UseTranslator['translate'],
  isEmpty?: boolean,
): HeaderCellProps[] => [
  {
    label: translate(''),
    accessor: 'check',
    disableSort: true,
    headerStyle: {
      borderTopLeftRadius: '0.5rem',
      paddingRight: '28px',
      ...(isEmpty && {
        width: '60px',
      }),
    },
    cellStyle: {
      width: '60px',
      paddingRight: '28px',
    },
  },
  {
    label: translate('SO Number/Ref'),
    accessor: 'number',
    disableSort: true,
    headerStyle: {
      paddingLeft: '5px',
    },
    cellStyle: {
      paddingLeft: '5px',
    },
  },
  {
    label: translate('Pickup Location'),
    accessor: 'pickupLocation',
    disableSort: true,
  },
  {
    label: translate('Dropoff Location'),
    accessor: 'dropoffLocation',
    disableSort: true,
  },
  {
    label: translate('Current Location'),
    accessor: 'currentLocation',
    disableSort: true,
  },
  {
    label: translate('Status'),
    accessor: 'status',
    ...(isEmpty && {
      headerStyle: {
        width: '80px',
      },
    }),
    disableSort: true,
  },
  {
    label: translate('Date'),
    accessor: 'soDate',
    disableSort: true,
  },
  {
    label: translate('Shipper'),
    accessor: 'shipper',
    disableSort: true,
    headerStyle: {
      borderTopRightRadius: '0.5rem',
    },
  },
];

export const joFormInitialValues = {
  joNumber: undefined,
  joDate: startOfToday(),
  driverId: undefined,
  vehicleId: undefined,
  travelExpenses: undefined,
  sealNumber: undefined,
  driverOption: undefined,
  vehicleOption: undefined,
  type: JOBOORDERTYPE.EXTERNAL,
  deliveryLocationType: DELIVERYLOCATIONTYPE.LAST_MILE,
  deliveries: [],
};

export const joSOCandidateFilterFormInitialValues: JOSOCandidateFilterFormInitialValues =
  {
    sortBy: JOSOCandidateFilterFormSortBy.UPDATED_AT_DESC,
    status: Object.values(JOSOCandidateFilterFormStatus),
    shipperId: undefined,
    locationId: undefined,
    startDate: undefined,
    endDate: undefined,
  };
