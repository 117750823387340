import tw, { styled } from 'twin.macro';
import { isOldSafari } from '../../../util/helper.util';

export const ArrowButtonContainer = tw.div`absolute z-[5]`;
export const ArrowButton = tw.button`w-[34px] h-[34px] rounded-full bg-grey-two flex items-center justify-center text-white fixed z-[4]`;

export const Container = tw.div`rounded-lg shadow-card bg-white`;
export const TableContainer = styled.div<{ stickyHeader?: boolean }>`
  ${tw`w-full rounded-lg overflow-x-auto relative`}
  ${({ stickyHeader }) => (stickyHeader ? tw`h-[600px]` : '')}
  ::-webkit-scrollbar {
    ${tw`w-[7px] h-[7px] rounded-[10px]`}
  }
  ::-webkit-scrollbar-track {
    ${tw`rounded-[10px]`}
  }
  ::-webkit-scrollbar-thumb {
    ${tw`rounded-[10px] bg-grey-four`}
  }
`;

export const HTMLTable = styled.table(
  ({
    stickyHeader,
    stickyHeaderPositionTop,
    stickyColumn,
    stickyColumnNumber = 2,
    fullWidth,
  }: {
    stickyHeader?: boolean;
    stickyHeaderPositionTop?: number;
    stickyColumn?: boolean;
    stickyColumnNumber?: number;
    fullWidth?: boolean;
  }) => [
    tw`font-sans font-size[14px] border-separate table-fixed whitespace-nowrap border-spacing[0]`,
    fullWidth && tw`w-full`,
    stickyHeader &&
      `& thead tr th {
        position: sticky !important;
        top: 0;
        z-index: 1;
      }
      & thead tr:not(:first-of-type) ${isOldSafari() ? 'th' : ''}  {
        position: sticky !important;
        top: 49px;
        z-index: 2;
      }`,
    stickyColumn &&
      `& thead th {
        position: sticky;
        top: ${stickyHeaderPositionTop ? `${stickyHeaderPositionTop}px` : '0'};
        z-index: 2;
      }
      & tbody th {
        position: sticky;
        top: 0;
        z-index: 2;
      }
      & thead th:first-of-type {
        position: sticky;
        left: 0;
        z-index: 3;
      }
      & thead th:nth-of-type(${String(stickyColumnNumber)}) {
        position: sticky;
        z-index: 3;
        left: ${stickyColumnNumber && stickyColumnNumber > 1 ? '50px' : '0px'}
      }
      & thead th:nth-of-type(${String(stickyColumnNumber)})::after {
        content: ' ';
        width: 11px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -11px;
        background: linear-gradient(to right, rgba(49, 49, 49, 0.1), transparent);
      }
      & tr td:first-of-type {
        position: sticky;
        left: 0;
        z-index: 1;
      }
      & tr td:nth-of-type(${String(stickyColumnNumber)}) {
        position: sticky;
        z-index: 1;
        left: ${stickyColumnNumber && stickyColumnNumber > 1 ? '50px' : '0px'}
      }
      & tr td:nth-of-type(${String(stickyColumnNumber)})::after {
        content: ' ';
        width: 11px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -11px;
        background: linear-gradient(to right, rgba(49, 49, 49, 0.1), transparent);
      }`,
  ],
);

export const HeaderCol = styled.th(
  ({
    displayNone,
    headerGroupIdx,
    colSpan,
    displayBorder,
  }: {
    displayNone: boolean;
    headerGroupIdx: number;
    colSpan: number;
    displayBorder?: boolean;
  }) => [
    tw`border-0 font-semibold px-5 py-4 bg-orange text-white text-left relative leading-4`,
    displayBorder && tw`border-b border-r border-orange-three`,
    displayNone && tw`hidden`,
    headerGroupIdx === 0 &&
      tw`first-of-type:border-top-left-radius[6px] last-of-type:border-top-right-radius[6px]`,
    colSpan > 1 && tw`text-center`,
  ],
);
export const Cell = styled.td(
  (props: { isExpanded?: boolean; isExpandedAndFirstColumn?: boolean }) => [
    tw`align-top px-5 py-4 bg-white duration-200 border-b border-l border-b-beige-lines border-l-transparent group-hover:bg-orange-hover`,
    props.isExpanded && tw`border-b-0`,
    props.isExpandedAndFirstColumn && tw`border-l-orange`,
  ],
);
export const ExpandedCell = styled.tr((props: { isExpanded: boolean }) => [
  tw`px-5 py-4 h-full width[1000px] flex flex-auto flex-col bg-white peer-hover:bg-orange-hover animate-[slide-in-top 0.25s ease-in-out both]`,
  props.isExpanded &&
    tw`border-l border-l-orange border-b border-b-beige-lines`,
]);
export const ExpandedCellColumn = tw.td`flex flex-col h-full w-full`;
export const PaginationContainer = tw.div`rounded-b-lg flex flex-row items-center justify-between w-auto px-4 py-4 bg-white text-grey-three border-t border-beige-lines`;

export const StyledReactPaginate = styled.div`
  ${tw`flex items-center`}
  & .pagination {
    ${tw`flex flex-row`}
  }
  & .break-me {
    ${tw`cursor-default`}
  }
  & .active {
    ${tw`border-radius[3px] border-transparent bg-orange text-white`}
  }
  & a[role='button'] {
    ${tw`my-0 mx-2`}
  }
`;
export const PaginationDropdown = tw.div`flex items-center space-x-1`;
export const IconWrapper = styled.div(
  (props: { expanded?: boolean; disabled?: boolean }) => [
    tw`items-center p-1 -mx-1 rounded-full transform[rotate(90deg)] duration-200 text-current`,
    !props.disabled && tw`hover:bg-orange-hover`,
    props.expanded && tw`transform[rotate(-90deg)]`,
  ],
);
// #endregion
