import React, {
  createRef,
  CSSProperties,
  Fragment,
  MutableRefObject,
  useState,
} from 'react';
import tw, { styled, theme } from 'twin.macro';
import { List, OutsideAlerter, Popover } from '../../atom';
import { ExtraStyle } from '../../Type.component';

export const Container = tw.div`rounded-lg shadow-card`;

export const TableContainer = styled.div(() => [
  tw`w-full rounded-lg scrollbar-width[thin] relative`,
  `::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #F8CBBD
    }
    `,
]);

export const HTMLTable = styled.table(
  ({
    stickyHeader,
    stickyColumn,
  }: { stickyHeader?: boolean; stickyColumn?: boolean }) => [
    tw`w-full font-sans font-size[14px] border-separate table-fixed whitespace-nowrap border-spacing[0]`,
    stickyHeader &&
      `& thead tr th {
          position: sticky !important;
          top: 0;
          z-index: 1;
        }`,
    stickyColumn &&
      `width: auto;
        & thead th {
          position: sticky;
          top: 0;
          z-index: 2;
          width: 25vw;
        }
        & tbody th {
          position: sticky;
          left: 0;
          z-index: 2;
        }
        & thead th:first-of-type {
          position: sticky;
          left: 0;
          z-index: 2;
        }
        & thead th:nth-of-type(2) {
          position: sticky;
          left: 69px;
          z-index: 2;
        }
        & thead th:nth-of-type(2)::after {
          content: ' ';
          width: 11px;
          height: 100%;
          position: absolute;
          top: 0;
          right: -11px;
          background: linear-gradient(to right, rgba(49, 49, 49, 0.1), transparent);
        }
        & tr td:first-of-type {
          position: sticky;
          left: 0;
          z-index: 1;
        }
        & tr td:nth-of-type(2) {
          position: sticky;
          left: 69px;
          z-index: 1;
        }
        & tr td:nth-of-type(2)::after {
          content: ' ';
          width: 11px;
          height: 100%;
          position: absolute;
          top: 0;
          right: -11px;
          background: linear-gradient(to right, rgba(49, 49, 49, 0.1), transparent);
        }`,
  ],
);

export const NumberingHeaderCell = tw.th`bg-black w-[30px] box-border`;

export const Header = styled.th(({ open }: { open?: boolean }) => [
  tw`border-0 border-b font-semibold px-5 py-4 bg-orange text-white text-left relative leading-4`,
  open &&
    tw`before:(content-[' '] absolute bottom-0 left-0 right-0 h-1 bg-orange-four)`,
]);

export const SortIndicator = tw.span`ml-2`;
export const NumberingCell = tw.td`text-right pr-1 bg-white group-hover:(bg-orange-hover) duration-200`;
export const Cell = tw.td`align-top px-5 py-4 bg-white group-hover:(bg-orange-hover) duration-200 border-b border-beige-lines`;
export const PaginationContainer = tw.div`rounded-b-lg flex flex-row items-center justify-between w-auto px-4 py-4 bg-white text-grey-three border-t border-beige-lines`;
export const StyledReactPaginate = styled.div`
  display: flex;
  align-items: center;
  .pagination {
    display: flex;
    flex-direction: row;
  }
  .break-me {
    cursor: default;
  }
  .active {
    border-radius: 3px;
    border-color: transparent;
    background-color: #f3532e;
    color: white;
  }
  a[role='button'] {
    margin: 0 0.5rem;
  }
`;

export const PaginationDropdown = tw.div`flex items-center space-x-1`;

export const IconWrapper = styled.div(
  (props: { expanded?: boolean; disabled?: boolean }) => [
    tw`items-center p-1 -mx-1 rounded-full rotate-90 duration-200 text-current`,
    !props.disabled && tw`hover:bg-orange-hover`,
    props.expanded && tw`-rotate-90`,
  ],
);

export type HeaderCellProps = {
  label: string;
  custom?: React.ReactNode;
  accessor: string;
  disableSort?: boolean;
  headerStyle?: CSSProperties;
  cellStyle?: CSSProperties;
  sortLabel?: {
    asc: string;
    desc: string;
  };
  sort?: {
    selected: boolean;
    asc: boolean;
  };
  onClickSort?: (asc: boolean) => void;
};

export function HeaderCell({
  label,
  headerStyle,
  sortLabel,
  sort,
  onClickSort,
  custom,
  disableSort,
  accessor,
}: HeaderCellProps) {
  const [open, setOpen] = useState<boolean>(false);
  const ref = createRef<HTMLTableCellElement>();

  const handleHeaderClick = () => {
    if (onClickSort) {
      if (!sortLabel && disableSort) {
        if (sort?.selected) {
          onClickSort(!sort.asc);
        } else {
          onClickSort(false);
        }
      } else {
        setOpen(!open);
      }
    }
  };

  return (
    <Header
      key={accessor}
      ref={ref}
      style={headerStyle}
      onClick={handleHeaderClick}
      open={open}
    >
      <OutsideAlerter onClickAway={() => setOpen(false)}>
        {custom || label}
        {sort?.selected && (
          <SortIndicator>{sort.asc ? <>&uarr;</> : <>&darr;</>}</SortIndicator>
        )}
        {onClickSort && sortLabel && (
          <Popover
            targetRef={ref as MutableRefObject<null>}
            visible={open}
            tw="w-full text-black font-normal"
          >
            <List.Regular
              options={[
                { label: sortLabel.asc, value: 'asc' },
                { label: sortLabel.desc, value: 'desc' },
              ]}
              onClickItem={(option) => onClickSort(option.value === 'asc')}
            />
          </Popover>
        )}
      </OutsideAlerter>
    </Header>
  );
}

export type TableRow = {
  [key: string]: React.ReactNode;
};

export type BaseProps = React.ComponentProps<'table'> & {
  columns: HeaderCellProps[];
  rows: TableRow[];
  showNumbering?: boolean;
  stickyHeader?: boolean;
  stickyColumn?: boolean;
  noDataPage?: React.ReactNode;
  renderExpandedPage?: (row: TableRow) => React.ReactNode;
  cellTwStyle?: ExtraStyle;
  showBottomBorderForLastRow?: boolean;
};

export function TableBase({
  columns,
  rows,
  showNumbering,
  stickyHeader,
  stickyColumn,
  noDataPage,
  renderExpandedPage,
  cellTwStyle,
  showBottomBorderForLastRow = false,
  ...props
}: BaseProps) {
  const [sortAccessor, setSortAccessor] = useState<string>();
  const [sortAsc, setSortAsc] = useState<boolean>(false);

  return (
    <>
      <HTMLTable
        stickyHeader={stickyHeader}
        stickyColumn={stickyColumn}
        {...props}
      >
        <thead>
          <tr>
            {showNumbering && <NumberingHeaderCell />}
            {columns.map(
              (
                {
                  label,
                  accessor,
                  headerStyle,
                  sortLabel,
                  disableSort,
                  custom,
                },
                idx,
              ) => (
                <HeaderCell
                  accessor={accessor}
                  key={`TableHeader-${accessor}`}
                  label={label}
                  headerStyle={{
                    ...headerStyle,
                    ...(rows.length === 0 && {
                      borderBottomWidth: '1px',
                    }),
                    ...(rows.length === 0 &&
                      idx === 0 && {
                        borderBottomLeftRadius: '0.5rem',
                      }),
                    ...(rows.length === 0 &&
                      idx === columns.length - 1 && {
                        borderBottomRightRadius: '0.5rem',
                      }),
                  }}
                  sortLabel={sortLabel}
                  custom={custom}
                  sort={
                    sortAccessor === accessor
                      ? {
                          selected: true,
                          asc: sortAsc,
                        }
                      : undefined
                  }
                  onClickSort={
                    disableSort
                      ? undefined
                      : (asc) => {
                          setSortAccessor(accessor);
                          setSortAsc(asc);
                        }
                  }
                />
              ),
            )}
          </tr>
        </thead>

        {rows && rows.length > 0 && (
          <tbody>
            {rows.map((row, rowIdx) => (
              <Fragment key={`TableRow_row_${rowIdx}`}>
                <tr className="group" tw="relative">
                  {showNumbering && <NumberingCell>{rowIdx + 1}</NumberingCell>}
                  {columns.map(({ accessor, cellStyle }, colIdx) => {
                    const sharedCellStyle: CSSProperties = {
                      ...cellStyle,
                      ...((rowIdx === 0 || colIdx === rows.length - 1) && {
                        borderTopWidth: 0,
                      }),
                      ...(showBottomBorderForLastRow && {
                        borderBottomWidth: '1px',
                        borderBottomColor: theme`colors.beige.lines`,
                      }),
                      ...(showBottomBorderForLastRow &&
                        rowIdx + 1 === rows.length &&
                        colIdx === 0 && {
                          borderBottomLeftRadius: '0.5rem',
                        }),
                      ...(showBottomBorderForLastRow &&
                        rowIdx + 1 === rows.length &&
                        colIdx + 1 === columns.length && {
                          borderBottomRightRadius: '0.5rem',
                        }),
                    };

                    if (
                      Object.keys(row).length !== columns.length &&
                      Object.keys(row).length === 1 &&
                      row[accessor]
                    )
                      return (
                        <Cell
                          tw="border-b-beige-lines pt-1 relative"
                          key={`TableRow_row_${rowIdx}_${accessor}`}
                          colSpan={columns.length}
                          style={sharedCellStyle}
                          css={cellTwStyle}
                        >
                          {row[accessor]}
                        </Cell>
                      );

                    return (
                      <Cell
                        tw="border-b border-t border-t-beige-lines relative"
                        key={`TableRow_row_${rowIdx}_${accessor}`}
                        style={sharedCellStyle}
                        css={cellTwStyle}
                      >
                        {row[accessor]}
                      </Cell>
                    );
                  })}
                </tr>

                {renderExpandedPage?.(row)}
              </Fragment>
            ))}
          </tbody>
        )}
      </HTMLTable>
      {rows && rows.length < 1 && noDataPage}
    </>
  );
}
