import React, { forwardRef, ForwardRefExoticComponent, Ref } from 'react';
import tw from 'twin.macro';
import { ExtraStyle } from '../../Type.component';

interface Props {
  children: React.ReactNode;
  containerStyle?: ExtraStyle;
  id?: string;
  ref?: Ref<HTMLDivElement>;
}

const Content = tw.div`w-[1032px] mx-auto px-4`;

const Container: ForwardRefExoticComponent<Props> = forwardRef(
  function Container({ children, containerStyle, id }, ref) {
    return (
      <Content ref={ref} css={containerStyle} id={id}>
        {children}
      </Content>
    );
  },
);

export default Container;
