import React, { LegacyRef, MutableRefObject } from 'react';
import { isMobile } from 'react-device-detect';
import ReactPaginate from 'react-paginate';
import 'twin.macro';
import useTableHandler, {
  UseTableHandlerProps,
} from '../../../hook/useTableHandler/useTableHandler.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { Icon, List, Popover, Text } from '../../atom';
import { ChevronSharp } from '../../atom/Icon/Icon.atom';
import { ExtraStyle } from '../../Type.component';
import {
  ArrowButton,
  ArrowButtonContainer,
} from '../TanstackTable/TanstackTableElement.molecule';
import {
  BaseProps,
  Container,
  IconWrapper,
  PaginationContainer,
  PaginationDropdown,
  StyledReactPaginate,
  TableBase,
  TableContainer,
} from './TableElement.molecule';

// #region INTERFACES

type Props = React.ComponentProps<'div'> &
  BaseProps &
  UseTableHandlerProps & {
    noDataPage?: React.ReactNode;
    containerStyle?: ExtraStyle;
    rootContainerStyle?: ExtraStyle;
    pageTotal?: number;
    summaryHeader?: React.ReactNode;
    leftArrowPosition?: number;
    rightArrowPosition?: number;
    onPageClick?: (page: number) => void;
    onPageLimitClick?: (limit: number) => void;
  };
// #endregion

export default function Table({
  columns,
  noDataPage,
  renderExpandedPage,
  rows,
  showNumbering,
  stickyHeader = true,
  stickyColumn,
  containerStyle,
  rootContainerStyle,
  pageLimit = 0,
  onPageLimitClick,
  pageCurrent = 0,
  pageTotal = 0,
  onPageClick,
  leftArrowPosition = 0,
  rightArrowPosition = 0,
  dataTotal = 0,
  isScrollableActionVisible,
  summaryHeader,
  cellTwStyle,
  showBottomBorderForLastRow,
  ...props
}: Props) {
  const translator = useTranslator();

  const {
    paginationRef,
    paginationDropdown,
    setPaginationDropdown,
    paginationOptions,
    showingLeft,
    tableContainerRef,
    isLeftArrowVisible,
    isRightArrowVisible,
    isScrollActionArrowVisible,
    overflowXIndicatorStyle,
    showingRight,
  } = useTableHandler({
    pageCurrent,
    rows,
    pageLimit,
    dataTotal,
    isScrollableActionVisible,
  });

  return (
    <Container css={rootContainerStyle}>
      <TableContainer
        ref={tableContainerRef as LegacyRef<HTMLDivElement>}
        css={containerStyle}
        {...props}
      >
        {summaryHeader}
        {isScrollActionArrowVisible && (
          <>
            {isLeftArrowVisible && (
              <ArrowButtonContainer
                style={{
                  ...overflowXIndicatorStyle,
                  left: leftArrowPosition - 15,
                }}
              >
                <ArrowButton
                  onClick={() => {
                    tableContainerRef.current?.scrollBy({
                      left: -500,
                      behavior: 'smooth',
                    });
                  }}
                  type="button"
                  tw={'rotate-180'}
                >
                  <ChevronSharp />
                </ArrowButton>
              </ArrowButtonContainer>
            )}
            {isRightArrowVisible && (
              <ArrowButtonContainer
                style={{
                  ...overflowXIndicatorStyle,
                  right: rightArrowPosition + 15,
                }}
              >
                <ArrowButton
                  onClick={() =>
                    tableContainerRef.current?.scrollBy({
                      left: 500,
                      behavior: 'smooth',
                    })
                  }
                  type="button"
                >
                  <ChevronSharp />
                </ArrowButton>
              </ArrowButtonContainer>
            )}
          </>
        )}
        <TableBase
          showNumbering={showNumbering}
          columns={columns}
          rows={rows}
          stickyHeader={stickyHeader}
          stickyColumn={stickyColumn}
          cellTwStyle={cellTwStyle}
          showBottomBorderForLastRow={showBottomBorderForLastRow}
          renderExpandedPage={renderExpandedPage}
        />

        {rows && rows.length < 1 && noDataPage}
      </TableContainer>
      {rows && rows.length > 0 && onPageClick && onPageLimitClick && (
        <PaginationContainer>
          {!isMobile && (
            <PaginationDropdown ref={paginationRef} tw="flex">
              <Text.BodyTwo tw="font-semibold text-grey-three">
                {translator.translate('Show')}
                {': '}
                {pageLimit}
              </Text.BodyTwo>
              <IconWrapper
                expanded={paginationDropdown}
                onClick={(e) => {
                  e.stopPropagation();
                  setPaginationDropdown(!paginationDropdown);
                }}
              >
                <Icon.ChevronSharp height={18} width={18} strokeWidth={2.5} />
              </IconWrapper>
              <Popover
                visible={paginationDropdown}
                targetRef={paginationRef as MutableRefObject<null>}
                style={{ zIndex: 10 }}
              >
                <List.Small
                  tw="w-full mx-4"
                  options={paginationOptions}
                  onClickItem={(option) => {
                    onPageLimitClick?.(parseInt(option.value, 10));
                    setPaginationDropdown(false);
                  }}
                />
              </Popover>
            </PaginationDropdown>
          )}

          <StyledReactPaginate>
            <Text.Label>
              {`${showingLeft} - ${showingRight} ${translator.translate(
                'of',
              )} ${dataTotal}`}
            </Text.Label>

            <ReactPaginate
              onPageChange={(e: { selected: number }) => {
                onPageClick?.(e.selected + 1);
                setPaginationDropdown(false);
              }}
              forcePage={pageCurrent - 1}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={pageTotal || 0}
              previousLabel={null}
              nextLabel={null}
              breakClassName="break-me"
              containerClassName="pagination"
              activeClassName="active"
            />
          </StyledReactPaginate>
        </PaginationContainer>
      )}
    </Container>
  );
}
