import { flexRender, Row, RowData } from '@tanstack/react-table';
import React, { Fragment, LegacyRef, MutableRefObject } from 'react';
import ReactPaginate from 'react-paginate';
import tw, { css } from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import {
  ColumnMeta,
  FooterOptions,
  InfiniteScrollingOptions,
  RenderSubComponentProps,
  TableStyleProps,
} from '../../../constant';
import useTanstackTableHandler, {
  UseTanstackTableHandlerProps,
} from '../../../hook/useTanstackTableHandler/useTanstackTableHandler.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { Icon, List, LoadingIndicator, Popover, Text } from '../../atom';
import { ChevronSharp } from '../../atom/Icon/Icon.atom';
import {
  ArrowButton,
  ArrowButtonContainer,
  Cell,
  Container,
  ExpandedCell,
  ExpandedCellColumn,
  HeaderCol,
  HTMLTable,
  IconWrapper,
  PaginationContainer,
  PaginationDropdown,
  StyledReactPaginate,
  TableContainer,
} from './TanstackTableElement.molecule';

type Props<RD extends RowData> = UseTanstackTableHandlerProps<RD> &
  TableStyleProps & {
    forceLang?: SupportedLanguage;
    onPageLimitClick?: (limit: number) => void;
    onPageClick?: (page: number) => void;
    renderSubComponent?: (
      props: RenderSubComponentProps<RD>,
    ) => React.ReactNode;
    noDataPage?: React.ReactNode;
    fullWidth?: boolean;
    displayHeaderBorder?: boolean;
    trProps?:
      | ((row: Row<RD>) => React.ComponentPropsWithoutRef<'tr'>)
      | React.ComponentPropsWithoutRef<'tr'>;
    expandedPage?: React.ReactNode;
    expandedRowWidthFitContent?: boolean;
    infiniteScrollingOptions?: InfiniteScrollingOptions;
    footerOptions?: FooterOptions;
    testID?: string;
    bottomActionElement?: React.ReactNode;
  };

function TanstackTable<RD extends RowData>({
  onPageLimitClick,
  onPageClick,
  table,
  infiniteScrollingOptions,
  isScrollableActionVisible,
  limitOptions,
  noDataPage,
  stickyHeader,
  stickyHeaderPositionTop,
  stickyColumn,
  stickyColumnNumber = stickyColumn ? 2 : 0,
  fullWidth = true,
  displayHeaderBorder = false,
  trProps,
  expandedPage,
  renderSubComponent,
  rootStyle,
  tableContainerStyle,
  paginationContainerStyle,
  expandedRowStyle,
  expandedRowWidthFitContent = false,
  footerOptions,
  testID,
  forceLang,
  bottomActionElement,
  rightArrowOffset = 15,
}: Props<RD>) {
  let totalWidthRow: number | string = 0;
  const translator = useTranslator();

  const {
    tableContainerRef,
    tableContainerHeight,
    tableHeaderHeight,
    rows,
    isLeftArrowVisible,
    isRightArrowVisible,
    overflowXIndicatorStyle,
    headerGroups,
    tableHeaderRef,
    tableBodyRef,
    virtualizer,
    hasNextPage,
    paginationRef,
    isTableArrowVisible,
    pageLimit,
    paginationDropdown,
    paginationOptions,
    showingLeft,
    showingRight,
    dataTotal,
    leftArrowLeftPosition,
    pageCurrent,
    pageTotal,
    setPaginationDropdown,
  } = useTanstackTableHandler({
    table,
    infiniteScrollingOptions,
    stickyColumn,
    stickyColumnNumber,
    stickyHeader,
    stickyHeaderPositionTop,
    isScrollableActionVisible,
    limitOptions,
  });

  return (
    <Container css={rootStyle}>
      <TableContainer
        ref={tableContainerRef as LegacyRef<HTMLDivElement>}
        stickyHeader={stickyHeader}
        onScroll={
          infiniteScrollingOptions?.onScrollTableContainer ??
          footerOptions?.onScrollTableContainer
        }
        css={tableContainerStyle}
      >
        {isScrollableActionVisible &&
          !!tableContainerHeight &&
          !!tableHeaderHeight &&
          isTableArrowVisible &&
          rows.length > 0 && (
            <>
              {isLeftArrowVisible && (
                <ArrowButtonContainer
                  style={{
                    ...overflowXIndicatorStyle,
                    left: leftArrowLeftPosition,
                  }}
                >
                  <ArrowButton
                    onClick={() => {
                      tableContainerRef.current?.scrollBy({
                        left: -500,
                        behavior: 'smooth',
                      });
                    }}
                    type="button"
                    tw={'rotate-180'}
                  >
                    <ChevronSharp />
                  </ArrowButton>
                </ArrowButtonContainer>
              )}
              {isRightArrowVisible && (
                <ArrowButtonContainer
                  style={{
                    ...overflowXIndicatorStyle,
                    right: rightArrowOffset,
                  }}
                >
                  <ArrowButton
                    onClick={() =>
                      tableContainerRef.current?.scrollBy({
                        left: 500,
                        behavior: 'smooth',
                      })
                    }
                    type="button"
                  >
                    <ChevronSharp />
                  </ArrowButton>
                </ArrowButtonContainer>
              )}
            </>
          )}
        <HTMLTable
          stickyHeader={stickyHeader}
          stickyHeaderPositionTop={stickyHeaderPositionTop}
          stickyColumn={stickyColumn}
          stickyColumnNumber={stickyColumnNumber}
          fullWidth={fullWidth}
        >
          <thead ref={tableHeaderRef}>
            {headerGroups.map((headerGroup, headerGroupIdx) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const headerColumnMeta = header.column.columnDef
                    .meta as ColumnMeta;

                  return (
                    <HeaderCol
                      style={{ width: header.getSize() }}
                      css={[
                        headerColumnMeta?.cellStyle,
                        headerColumnMeta?.headerStyle,
                      ]}
                      key={header.id}
                      displayBorder={displayHeaderBorder}
                      displayNone={
                        !!headerColumnMeta?.rowSpan && headerGroupIdx !== 0
                      }
                      headerGroupIdx={headerGroupIdx}
                      colSpan={header.colSpan}
                      rowSpan={headerColumnMeta?.rowSpan ?? 1}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </HeaderCol>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody
            ref={tableBodyRef}
            style={
              infiniteScrollingOptions?.useVirtualizer
                ? {
                    height: `${virtualizer.getTotalSize()}px`,
                    width: '100%',
                    position: 'relative',
                    display: 'block',
                  }
                : undefined
            }
          >
            {rows.length > 0 && infiniteScrollingOptions?.useVirtualizer
              ? virtualizer.getVirtualItems().map((virtualRow) => {
                  const isLoaderRow = virtualRow.index > rows.length - 1;
                  const item = rows[virtualRow.index];

                  if (!totalWidthRow) {
                    totalWidthRow = item
                      .getVisibleCells()
                      .reduce(
                        (prev, acc) =>
                          prev + (acc.column.getSize() + (fullWidth ? 0 : 40)),
                        0,
                      );
                  }

                  const isLoaderRowAndHasNextPage = hasNextPage ? (
                    <td
                      style={{
                        width: totalWidthRow,
                        height: `${virtualRow.size + 25}px`,
                      }}
                      colSpan={100}
                    >
                      <div style={{ display: 'grid', placeItems: 'center' }}>
                        <LoadingIndicator size="small" />
                      </div>
                    </td>
                  ) : (
                    <td
                      style={{
                        width: totalWidthRow,
                        height: `${virtualRow.size}px`,
                      }}
                      colSpan={100}
                    >
                      <div style={{ display: 'grid', placeItems: 'center' }}>
                        Nothing more to load
                      </div>
                    </td>
                  );

                  return (
                    <tr
                      key={virtualRow.index}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: totalWidthRow,
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start}px)`,
                      }}
                      className={trProps ? undefined : 'group'}
                      {...(typeof trProps === 'function'
                        ? trProps(item)
                        : trProps)}
                    >
                      {isLoaderRow
                        ? isLoaderRowAndHasNextPage
                        : item.getVisibleCells().map((cell) => {
                            const cellWidth = cell.column.getSize();

                            return (
                              <Cell
                                key={cell.id}
                                style={{ width: cellWidth }}
                                css={[
                                  (cell.column.columnDef.meta as ColumnMeta)
                                    ?.cellStyle,
                                  (
                                    cell.column.columnDef.meta as ColumnMeta
                                  )?.specificCellStyle?.(cell.row.id),
                                ]}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </Cell>
                            );
                          })}
                    </tr>
                  );
                })
              : rows.map((row) => {
                  if (!totalWidthRow) {
                    totalWidthRow = row
                      .getVisibleCells()
                      .reduce(
                        (prev, acc) =>
                          prev + (acc.column.getSize() + (fullWidth ? 0 : 40)),
                        0,
                      );
                  }

                  return (
                    <Fragment key={row.id}>
                      <tr
                        key={row.id}
                        css={[
                          ((renderSubComponent && row.getIsExpanded()) ||
                            (!!expandedPage && row.getIsExpanded())) &&
                            css`
                              &:hover + tr {
                                ${tw`bg-orange-hover`}
                              }
                            `,
                        ]}
                        className={trProps ? undefined : 'group'}
                        {...(typeof trProps === 'function'
                          ? trProps(row)
                          : trProps)}
                      >
                        {row.getVisibleCells().map((cell, idx) => (
                          <Cell
                            key={cell.id}
                            isExpanded={row.getIsExpanded()}
                            isExpandedAndFirstColumn={
                              row.getIsExpanded() && idx === 0
                            }
                            css={[
                              (cell.column.columnDef.meta as ColumnMeta)
                                ?.cellStyle,
                              (
                                cell.column.columnDef.meta as ColumnMeta
                              )?.specificCellStyle?.(cell.row.id),
                            ]}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Cell>
                        ))}
                      </tr>

                      {expandedPage && row.getIsExpanded() && (
                        <ExpandedCell isExpanded css={expandedRowStyle}>
                          {expandedPage}
                        </ExpandedCell>
                      )}

                      {renderSubComponent && row.getIsExpanded() && (
                        <ExpandedCell
                          isExpanded
                          style={{
                            width: expandedRowWidthFitContent
                              ? 'fit-content'
                              : totalWidthRow,
                          }}
                          css={expandedRowStyle}
                        >
                          <ExpandedCellColumn>
                            {renderSubComponent({ row })}
                          </ExpandedCellColumn>
                        </ExpandedCell>
                      )}
                    </Fragment>
                  );
                })}
          </tbody>
        </HTMLTable>
        {rows.length < 1 && noDataPage}
      </TableContainer>

      {footerOptions && (
        <TableContainer
          ref={footerOptions.ref}
          tw="w-full flex overflow-x-hidden rounded-t-none pointer-events-none"
          style={{
            height: `${
              footerOptions.containerStyle?.height as number | string
            }px`,
          }}
        >
          {footerOptions.data.map(({ id, node, cellStyle }) => (
            <div
              key={id}
              style={cellStyle}
              tw="px-5 py-4 bg-white border-t border-beige-lines"
            >
              {node}
            </div>
          ))}
        </TableContainer>
      )}
      {rows?.length > 0 && onPageClick && onPageLimitClick && (
        <PaginationContainer css={paginationContainerStyle}>
          <PaginationDropdown ref={paginationRef} tw="flex">
            <Text.BodyTwo tw="font-semibold text-grey-three">
              {translator.translate('Show', forceLang)}: {pageLimit}
            </Text.BodyTwo>
            <IconWrapper
              data-testid={`${testID || ''}Table:ToggleNumberPerPage`}
              expanded={paginationDropdown}
              onClick={(e) => {
                e.stopPropagation();
                setPaginationDropdown(!paginationDropdown);
              }}
            >
              <Icon.ChevronSharp height={18} width={18} strokeWidth={2.5} />
            </IconWrapper>
            <Popover
              visible={paginationDropdown}
              targetRef={paginationRef as MutableRefObject<null>}
              style={{ zIndex: 10 }}
            >
              <List.Small
                tw="w-full mx-4"
                options={paginationOptions}
                onClickItem={(option) => {
                  onPageLimitClick(parseInt(option.value, 10));
                  setPaginationDropdown(false);
                }}
                testID={`${testID || ''}Table:PageLimitOptions:`}
              />
            </Popover>
          </PaginationDropdown>

          <StyledReactPaginate>
            <Text.Label>
              {`${showingLeft} - ${showingRight} ${translator.translate(
                'of',
                forceLang,
              )} ${dataTotal}`}
            </Text.Label>

            <ReactPaginate
              onPageChange={(e: { selected: number }) => {
                onPageClick(e.selected + 1);
                setPaginationDropdown(false);
              }}
              forcePage={pageCurrent - 1}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={pageTotal || 0}
              previousLabel={null}
              nextLabel={null}
              breakClassName="break-me"
              containerClassName="pagination"
              activeClassName="active"
            />
          </StyledReactPaginate>
        </PaginationContainer>
      )}

      {bottomActionElement && (
        <PaginationContainer css={paginationContainerStyle}>
          {bottomActionElement}
        </PaginationContainer>
      )}
    </Container>
  );
}

export default TanstackTable;
