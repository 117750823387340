import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TableRow } from '../../component/molecule/Table/TableElement.molecule';

export type UseTableHandlerProps = {
  pageLimit?: number;
  pageCurrent?: number;
  rows: TableRow[];
  dataTotal?: number;
  isScrollableActionVisible?: boolean;
};

const DEFAULT_PAGINATION_OPTIONS = ['10', '25', '50', '100'];

export default function useTableHandler({
  pageCurrent = 0,
  pageLimit = 0,
  dataTotal = 0,
  rows,
  isScrollableActionVisible,
}: UseTableHandlerProps) {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const paginationRef = createRef<HTMLDivElement>();
  const tableHeaderRef = useRef<HTMLTableSectionElement | null>(null);
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);

  const [init, setInit] = useState<number | undefined>(undefined);
  const [paginationDropdown, setPaginationDropdown] = useState(false);
  const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false);
  const [isRightArrowVisible, setIsRightArrowVisible] = useState(true);

  const tableHeaderHeight = tableHeaderRef.current?.clientHeight || 0;

  const [tableContainerHeight, setTableContainerHeight] = useState(0);

  const overflowXIndicatorStyle = useMemo(() => {
    if (!init || !tableContainerRef.current) return {};

    const { innerHeight } = window;

    return {
      top: Math.min(
        innerHeight / 2 - (init || 0) + 70,
        tableContainerHeight / 2,
      ),
    };
  }, [init, tableContainerHeight]);

  const paginationOptions = useMemo(
    () =>
      DEFAULT_PAGINATION_OPTIONS.map((option) => ({
        label: option,
        value: option,
      })),
    [],
  );

  const showingLeft = useMemo(
    () => (pageCurrent - 1) * pageLimit + 1,
    [pageLimit, pageCurrent],
  );

  const showingRight = useMemo(
    () =>
      pageCurrent * pageLimit < dataTotal ? pageCurrent * pageLimit : dataTotal,
    [pageCurrent, pageLimit, dataTotal],
  );

  const isScrollActionArrowVisible = useMemo(
    () =>
      isScrollableActionVisible && !!tableContainerHeight && rows.length > 0,
    [tableContainerHeight, isScrollableActionVisible, rows],
  );

  useEffect(() => {
    if (!tableContainerRef?.current || typeof window === 'undefined') return;
    if (init === undefined && top !== undefined) {
      const { top } = tableContainerRef.current.getBoundingClientRect();
      setInit(top);
    }
  }, [init]);

  // #region TABLE HORIZONTAL SCROLL ACTION
  const handleScrollTable = useCallback(() => {
    if (!tableContainerRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = tableContainerRef.current;
    setIsLeftArrowVisible(scrollLeft > 0);
    setIsRightArrowVisible(scrollLeft + clientWidth < scrollWidth - 50);
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: dependencies need to listen to allcolumns
  useEffect(() => {
    handleScrollTable();
  }, [rows, handleScrollTable]);

  useEffect(() => {
    if (!(isScrollableActionVisible && rows.length > 0)) return;

    const tableContainerElement = tableContainerRef.current;
    tableContainerElement?.addEventListener('scroll', handleScrollTable);
    tableContainerElement?.addEventListener('resize', handleScrollTable);

    handleScrollTable();

    return () => {
      tableContainerElement?.removeEventListener('scroll', handleScrollTable);
      tableContainerElement?.removeEventListener('resize', handleScrollTable);
    };
  }, [isScrollableActionVisible, handleScrollTable, rows.length]);

  // #endregion

  //#region TABLE VERTICAL SCROLL ACTION
  useEffect(() => {
    if (!tableContainerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setTableContainerHeight(tableContainerRef?.current?.clientHeight ?? 0);
    });
    resizeObserver.observe(tableContainerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);
  //#endregion

  return {
    isScrollActionArrowVisible,
    tableContainerRef,
    tableContainerHeight,
    isLeftArrowVisible,
    isRightArrowVisible,
    paginationRef,
    paginationDropdown,
    setPaginationDropdown,
    paginationOptions,
    showingLeft,
    overflowXIndicatorStyle,
    showingRight,
  };
}
